import './App.css';
import Header from './components/Header/header';
import About from './components/Body/about';
//import Grid from '@material-ui/core/Grid';


function App() {
  return (
    <div className="App">
      <Header />
      <About />
    <h1 className='comingSoon'>Coming Soon...</h1>
    </div>
  );
}

export default App;
